document.addEventListener('DOMContentLoaded', function () {

    const logoutButton = document.getElementById('logout');
    const logoutForm = document.getElementById('logout-form');

    logoutButton.addEventListener('click', (e) => {
        e.preventDefault();
        logoutForm.submit();
    });
});
