<template>
<!--    <ol>-->
        <li v-bind:key="value" v-for="view in view">{{ view }}</li>
<!--    </ol>-->
    <span class="flex text-lg font-semibold uppercase"> Dr. Carr  <svg class="ml-2 h-6 w-6 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />  <circle cx="8" cy="12" r="3" /></svg></span>
</template>

<script>
export default {
    mounted() {
        console.log('Component mounted.')
    },
    name: "ToggleButtons",
    props: {
        view: {
            DrCarr: true,
            DrDave: true,
            VIP: true,
            NP: true
        }
    }
}
</script>

<style>
li{
    color: #2b2b2b;
}
</style>
