require("./bootstrap");
require("./dropdown");
require("./logout");
require("./select2");

import { createApp } from "vue";
import DocDash from "./components/DocDash.vue";
import ToggleButtons from "./components/ToggleButtons.vue";
import Toaster from "@meforma/vue-toaster";

const app = createApp({
    components: {
        DocDash,
        ToggleButtons
    },

});


// REGISTER COMPONENTS GLOBALLY
// Register Button component globally
// app.component('TaskList', TaskList)


app.use(Toaster, {
    // One of the options
    position: "top-right",
    duration: 2000,
}).mount("#app");
