<template class="w-screen">
    <!-- MESSAGES -->
    <div v-if="loading">
        <h1 class="text-5xl text-steel-400">Loading...</h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 w-90" v-else>
        <!-- APPT DASH -->
        <div class="md:col-span-3 p-2">
            <!-- ON DECK ACTIONS -->
            <div class="flex mx-auto">
                <!-- appt links -->
                <div v-for="(week,index) in links" class="flex-col">
                    <span class="ml-6 font-bold underline">  {{ index + 1}} WEEK{{ index +1 > 1 ? 'S' : null}}</span>
                    <span v-for="link in week" class="flex gap-1 my-2">
                                    <button :class="link.style" class="flex-1" @click="bookNextAppt(link.date, link.hours)">{{ link.display}}</button>
                                </span><br />
                </div>
                <!-- clear or adjusted -->
                <div class="flex flex-col flex-initial">
                    <span class="flex-col ml-6 font-bold underline">Checkout</span>
                    <span class="flex-col gap-1 my-2 ">
                        <button class="py-2 px-4 m-2 rounded bg-emerald-600 text-sm" @click="updateApptType(12)">CLR</button><br />
                        <button class="py-2 px-4 m-2 rounded bg-blue-600 text-sm" @click="updateApptType(11)">ADJ</button><br />
                        <button class="py-2 px-4 m-2 rounded bg-blue-600 text-sm" @click="sendMessage()">MSG</button><br />
                    </span>
                </div>
                <!-- rescheduled or canceled -->
                <div class="flex flex-col flex-initial">
                    <span class="flex-col ml-6 font-bold underline">Change</span>
                    <span class="flex-col gap-1 my-2 mx-2 mx-auto" v-if="!this.viewFinished">
                        <button class="py-2 px-4 my-1 rounded bg-amber-300 text-sm" @click="updateApptStatus(3)">R/S</button><br />
                        <button class="py-2 px-4 my-1 rounded bg-rose-600 text-sm" @click="updateApptStatus(4)">CXL</button><br />
                        <button class="py-2 px-4 my-1 rounded bg-black text-white text-sm" @click="updateApptStatus(6)">N/S</button><br />
                    </span>
                    <span class="flex gap-1 my-2 mx-2 mx-auto" v-else>
                        <button class="py-2 px-4 my-1 rounded bg-amber-500 text-sm" @click="updateApptStatus(2)">CHECK IN</button><br />
                    </span>
                </div>
            </div>
            <!-- Toggleable Appointments List for the Day -->
            <table class="table">
                <thead>
                    <tr>
                        <td>Select All <input class="h-6 w-6" type="checkbox" @click="selectAll()" :checked="this.selected.length == this.getOnDeck.length"></td>
                        <td class="flex text-lg font-semibold"> {{ countOnDeck + '&nbsp;' }}
                            <span class="flex text-lg font-semibold uppercase" v-if="!this.viewFinished"> On Deck  <svg @click="this.viewFinished = !this.viewFinished" class="ml-2 h-6 w-6 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />  <circle cx="8" cy="12" r="3" /></svg></span>
                            <span class="flex text-lg font-semibold uppercase" v-if="this.viewFinished"> Checked Out<svg @click="this.viewFinished = !this.viewFinished" class="ml-2 h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />  <circle cx="16" cy="12" r="3" /></svg></span>
                        </td>
                        <td>Time </td>
                        <td>Patient</td>
                        <td>Type</td>
                    </tr>
                </thead>
                <tbody v-if="countOnDeck">
                    <tr v-for="(appt, index) in getOnDeck" :key="appt.id"
                        class="odd:bg-gray-300 hover:bg-blue-300"
                        v-bind:class="{
                        'bg-amber-300 text-red-700 font-bold tracking-wider odd:bg-amber-300': appt.mark_due && appt.appt_status_id == 2,
                        'bg-red-600 odd:bg-red-800' : appt.appt_status_id == 4 || appt.appt_status_id == 6,
                        'bg-gray-300 odd:bg-gray-500': appt.appt_status_id == 9 || appt.appt_status_id == 3,
                         'text-amber-200 font-bold tracking-wide': appt.mark_due && appt.appt_status_id !== 2}" >
                        <td class="p-4">
                            <input class="h-6 w-6" type="checkbox" @click="select(index)" :checked="selected.indexOf(index) > -1">
                        </td>
                        <td class="flex p-4">
                            <a :href="'/appts/'+ appt.id +'/edit'" class="mr-2">
                                <svg class="h-6 w-6 stroke-red-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                            </svg>
                            </a>
                            <a :href="'/appts/'+ appt.id" class="ml-2">
                                <svg class="h-6 w-6 text-blue-800"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                            </a>
                        </td>
                        <td class="p-4">{{ appt.appt_time }}</td>
                        <td v-if="appt.patient" class="p-4">{{  appt.patient.nickname }}</td>
                        <td class="p-4">
                            <span v-if="!viewFinished">{{ appt.appt_type ? appt.appt_type.appt_abbr : appt.appt_type_id }}</span>
                            <span v-else>{{ appt.appt_status ? appt.appt_status.status : appt.appt_status_id }}</span>
                        </td>
                </tr>
                </tbody>
                <tbody v-else>
                    <tr class="bg-indigo-400">
                        <td colspan="5">No appointments {{ viewFinished ? 'Checked Out' : 'On Deck' }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- Appt Modal -->
            <div class="modal" id="apptModal" tabindex="-1" aria-labelledby="apptModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title capitalize" id="apptModalLabel"> {{ modal }} Appt for <span v-if="appt && appt.patient" class="text-lg font-semibold">{{ appt.patient.nickname }} at {{ appt.appt_time}}</span> </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div v-if="appt && appt.appt_note" class="modal-subtitle">{{ appt.appt_note}}</div>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!-- SKELETON IMAGES COLUMN -->
                                <div class="col-4 flex-col">
                                    <img src="/images/skeleton1.png" class="hover:bg-amber-100" @click="viewButtons = 'skull'">
                                    <img src="/images/skeleton2.png" class="hover:bg-amber-200" @click="viewButtons = 'cervical'">
                                    <img src="/images/skeleton3.png" class="hover:bg-amber-300" @click="viewButtons = 'thoracic'">
                                    <img src="/images/skeleton4.png" class="hover:bg-amber-400" @click="viewButtons = 'lumbar'">
                                    <img src="/images/skeleton5.png" class="hover:bg-amber-500" @click="viewButtons = 'extremities'">
                                </div>
                                <!-- ADJUSTING BUTTONS COLUMN -->
                                <div class="col-5">
                                    <!-- LEFT / RIGHT -->
                                    <div class="flex" v-if="viewButtons">
                                        <button @click="addNotes('left ', 'assessment')" class="btn bg-emerald-500 flex-1">Left</button>
                                        <button @click="addNotes('right ', 'assessment')" class="btn bg-blue-500 flex-1">Right</button>
                                    </div>
                                    <!-- SKULL -->
                                    <div class="flex flex-col" v-if="viewButtons == 'skull' || viewButtons == 'extremities'">
                                        <button @click="addNotes('sinuses ', 'assessment')" class="flex-1 btn bg-slate-200">Sinuses</button>
                                        <button @click="addNotes('TMJ ', 'assessment')" class="flex-1 btn bg-slate-400">TMJ</button>
                                        <button @click="addNotes('ear ', 'assessment')" class="flex-1 btn bg-slate-600">ear</button>
                                    </div>
                                    <!-- OCCIPUT LISTINGS -->
                                    <div class="flex" v-if="viewButtons == 'cervical' || viewButtons == 'skull'">
                                        <button @click="addNotes('C0 ', 'assessment')" class="flex-1 btn bg-slate-800 text-white">C0</button>
                                    </div>
                                    <!-- CERVICAL LISTINGS -->
                                    <div v-if="viewButtons == 'cervical'">
                                        <div class="flex mx-auto">
                                            <button @click="addNotes('AILP ','assessment')" class="flex-1 btn bg-emerald-100">AILP</button>
                                            <button @click="addNotes('AILA ','assessment')" class="flex-1 btn bg-emerald-300">AILA</button>
                                            <button @click="addNotes('AIRA ', 'assessment')" class="flex-1 btn bg-blue-100">AIRA</button>
                                            <button @click="addNotes('AIRP ', 'assessment')" class="flex-1 btn bg-blue-300">AIRP</button>
                                        </div>
                                        <div class="flex mx-auto">
                                            <button @click="addNotes('ASLP ','assessment')" class="flex-1 btn bg-emerald-200">ASLP</button>
                                            <button @click="addNotes('ASLA ','assessment')" class="flex-1 btn bg-emerald-400">ASLA</button>
                                            <button @click="addNotes('ASRA ', 'assessment')" class="flex-1 btn bg-blue-200">ASRA</button>
                                            <button @click="addNotes('ASRP ', 'assessment')" class="flex-1 btn bg-blue-400">ASRP</button>
                                        </div>
                                        <div class="flex mx-auto">
                                            <button @click="addNotes('C2 SpL ', 'assessment')" class="flex-1 btn bg-emerald-100">SpL</button>
                                            <button @click="addNotes('C2 ESL ', 'assessment')" class="flex-1 btn bg-emerald-300">ESL</button>
                                            <button @click="addNotes('C2 BL ', 'assessment')" class="flex-1 btn bg-emerald-600">BL</button>
                                            <button @click="addNotes('C2 BR ', 'assessment')" class="flex-1 btn bg-blue-100">BR</button>
                                            <button @click="addNotes('C2 ESR ', 'assessment')" class="flex-1 btn bg-blue-300">ESR</button>
                                            <button @click="addNotes('C2 SpR ', 'assessment')" class="flex-1 btn bg-blue-600">SpR</button>
                                        </div>
                                        <div class="flex flex-col">
                                            <button @click="addNotes('C3 ', 'assessment')" class="flex-1 btn border border-1 rounded">C3</button>
                                            <button @click="addNotes('C4 ', 'assessment')" class="flex-1 btn border border-1 rounded">C4</button>
                                            <button @click="addNotes('C5 ', 'assessment')" class="flex-1 btn border border-1 rounded">C5</button>
                                            <button @click="addNotes('C6 ', 'assessment')" class="flex-1 btn border border-1 rounded">C6</button>
                                            <button @click="addNotes('C7 ', 'assessment')" class="flex-1 btn border border-1 rounded">C7</button>
                                        </div>
                                    </div>
                                    <!-- THORACIC / SCAPULA -->
                                    <div class="flex flex-col" v-if="viewButtons == 'thoracic' || viewButtons == 'extremities'">
                                        <div class="flex">
                                            <button @click="addNotes('1st rib ', 'assessment')" class="flex-1 btn bg-amber-100">1st rib</button>
                                            <button v-if="viewButtons !== 'extremities'" @click="addNotes('T1 ', 'assessment')" class="flex-1 btn btn-outline-dark">T1</button>
                                            <button @click="addNotes('anterior rib ', 'assessment')" class="flex-1 btn bg-amber-300">ant rib</button>
                                        </div>
                                        <div class="flex">
                                            <button @click="addNotes('superior clavicle ', 'assessment')" class="flex-1 btn bg-rose-300">S Clavicle</button>
                                            <button v-if="viewButtons !== 'extremities'" @click="addNotes('T2 ', 'assessment')" class="flex-1 btn btn-outline-dark">T2</button>
                                            <button @click="addNotes('inferior clavicle ', 'assessment')" class="flex-1 btn bg-violet-300">I Clavicle</button>
                                        </div>
                                        <div class="flex">
                                            <button @click="addNotes('medial scapula ', 'assessment')" class="flex-1 btn bg-rose-300">Med Scap</button>
                                            <button v-if="viewButtons !== 'extremities'" @click="addNotes('T3 ', 'assessment')" class="flex-1 btn btn-outline-dark">T3</button>
                                            <button @click="addNotes('lateral scapula ', 'assessment')" class="flex-1 btn bg-violet-300">Lat Scap</button>
                                        </div>
                                        <div class="flex flex-col" v-if="viewButtons == 'thoracic'">
                                            <button @click="addNotes('T4 ', 'assessment')" class="btn btn-outline-dark">T4</button>
                                            <button @click="addNotes('T5 ', 'assessment')" class="btn btn-outline-dark">T5</button>
                                            <button @click="addNotes('T6 ', 'assessment')" class="btn btn-outline-dark">T6</button>
                                            <button @click="addNotes('T7 ', 'assessment')" class="btn btn-outline-dark">T7</button>
                                            <button @click="addNotes('T8 ', 'assessment')" class="btn btn-outline-dark">T8</button>
                                            <button @click="addNotes('T9 ', 'assessment')" class="btn btn-outline-dark">T9</button>
                                            <button @click="addNotes('T10 ', 'assessment')" class="btn btn-outline-dark">T10</button>
                                            <button @click="addNotes('T11 ', 'assessment')" class="btn btn-outline-dark">T11</button>
                                            <button @click="addNotes('T12 ', 'assessment')" class="btn btn-outline-dark">T12</button>
                                        </div>
                                </div>
                                    <!-- LUMBAR / PELVIS -->
                                    <div class="flex flex-col" v-if="viewButtons == 'lumbar'">
                                        <button @click="addNotes('L1 ', 'assessment')" class="flex-1 btn btn-outline-dark">L1</button>
                                        <button @click="addNotes('L2 ', 'assessment')" class="flex-1 btn btn-outline-dark">L2</button>
                                        <button @click="addNotes('L3 ', 'assessment')" class="flex-1 btn btn-outline-dark">L3</button>
                                        <button @click="addNotes('L4 ', 'assessment')" class="flex-1 btn btn-outline-dark">L4</button>
                                        <button @click="addNotes('L5 ', 'assessment')" class="flex-1 btn btn-outline-dark">L5</button>
                                        <div class="flex">
                                            <button @click="addNotes('PIEX left ', 'assessment')" class="flex-1 btn bg-emerald-100">PIEX</button>
                                            <button @click="addNotes('PI ilium left ', 'assessment')" class="flex-1 btn bg-emerald-200">PI</button>
                                            <button @click="addNotes('PIIN left ', 'assessment')" class="flex-1 btn bg-emerald-300">PIIN</button>
                                            <button @click="addNotes('PIIN right ', 'assessment')" class="flex-1 btn bg-blue-100">PIIN</button>
                                            <button @click="addNotes('PI ilium right ', 'assessment')" class="flex-1 btn bg-blue-200">PI</button>
                                            <button @click="addNotes('PIEX right ', 'assessment')" class="flex-1 btn bg-blue-300">PIEX</button>
                                        </div>
                                        <div class="flex">
                                            <button @click="addNotes('ASEX left ', 'assessment')" class="flex-1 btn bg-emerald-300">ASEX</button>
                                            <button @click="addNotes('AS ilium left ', 'assessment')" class="flex-1 btn bg-emerald-400">AS</button>
                                            <button @click="addNotes('ASIN left ', 'assessment')" class="flex-1 btn bg-emerald-500">ASIN</button>
                                            <button @click="addNotes('ASIN right ', 'assessment')" class="flex-1 btn bg-blue-300">ASIN</button>
                                            <button @click="addNotes('AS ilium right ', 'assessment')" class="flex-1 btn bg-blue-400">AS</button>
                                            <button @click="addNotes('ASEX right ', 'assessment')" class="flex-1 btn bg-blue-500">ASEX</button>
                                        </div>
                                        <div class="flex">
                                            <button @click="addNotes('SAL ', 'assessment')" class="flex-1 btn bg-emerald-400">SAL</button>
                                            <button @click="addNotes('BP sacrum ', 'assessment')" class="flex-1 bg-cyan-100 btn">BP</button>
                                            <button @click="addNotes('SAR ', 'assessment')" class="flex-1 btn bg-blue-400">SAR</button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col" v-if="viewButtons == 'lumbar' || viewButtons == 'extremities'">
                                            <button @click="addNotes('pubic symphysis ', 'assessment')" class="flex-1 btn">Pubis</button>
                                            <button @click="addNotes('coccyx ', 'assessment')" class="flex-1 btn">Coccyx</button>
                                    </div>
                                    <!-- EXTREMITIES -->
                                    <div class="flex flex-col" v-if="viewButtons == 'extremities'">
                                            <button @click="addNotes('knee ', 'assessment')" class="flex-1 btn">knee</button>
                                            <button @click="addNotes('foot/ankle ', 'assessment')" class="flex-1 btn">talus</button>
                                    </div>

                                    <!-- TECHNIQUES -->
                                    <div class="flex" v-if="viewButtons == 'lumbar'">
                                        <button @click="addNotes('Webster technique', 'assessment')" class="flex-1 btn bg-violet-900 text-white">Webster</button>
                                    </div>
                                    <div class="flex" v-if="viewButtons == 'cervical' || viewButtons == 'skull'">
                                            <button @click="addNotes(' knee chest, ', 'assessment')" class="flex-1 btn bg-violet-200">Knee Chest</button>
                                            <button @click="addNotes(' wall knee chest, ', 'assessment')" class="flex-1 btn bg-violet-300">Wall</button>
                                            <button @click="addNotes(' toggle recoil, ', 'assessment')" class="flex-1 btn bg-violet-400">Toggle</button>
                                            <button @click="addNotes(' sustained contact, ', 'assessment')" class="flex-1 btn bg-violet-500">Pinkies</button>
                                        </div>
                                    <div class="flex" v-if="viewButtons">
                                        <button @click="addNotes('activator, ', 'assessment')" class="flex-1 btn bg-violet-600 text-white">Activator</button>
                                        <button @click="addNotes('drop, ', 'assessment')" class="flex-1 btn bg-violet-700 text-white">Thompson</button>
                                        <button @click="addNotes('diversified, ', 'assessment')" class="flex-1 btn bg-violet-700 text-white">Full Spine</button>
                                        <button @click="addNotes('seated, ', 'assessment')" class="flex-1 btn bg-violet-800 text-white">Seated</button>
                                        <button @click="addNotes('Gonstead, ', 'assessment')" class="flex-1 btn bg-violet-800 text-white">Gonstead</button>
                                    </div>
                                    <div class="flex" v-if="viewButtons">
                                        <span class="flex-1 btn">Pre:</span>
                                        <button @click="addNotes('+D left leg check, ', 'objective_text')" class="flex-1 btn bg-violet-400 text-white">+D left</button>
                                        <button @click="addNotes('-D left leg check, ', 'objective_text')" class="flex-1 btn bg-violet-400 text-white">-D</button>
                                        <button @click="addNotes('no leg length inequality, ', 'objective_text')" class="flex-1 btn bg-violet-500 text-white">Balanced</button>
                                        <button @click="addNotes('-D right leg check, ', 'objective_text')" class="flex-1 btn bg-violet-600 text-white">-D</button>
                                        <button @click="addNotes('+D right leg check, ', 'objective_text')" class="flex-1 btn bg-violet-600 text-white">+D</button>
                                    </div>
                                    <div class="flex" v-if="viewButtons">
                                        <span class="flex-1 btn">Post:</span>
                                        <button @click="addNotes('+D left leg check, ', 'plan')" class="flex-1 btn bg-violet-600 text-white">+D left</button>
                                        <button @click="addNotes('-D left leg check, ', 'plan')" class="flex-1 btn bg-violet-600 text-white">-D</button>
                                        <button @click="addNotes('no leg length inequality, ', 'plan')" class="flex-1 btn bg-violet-700 text-white">Balanced</button>
                                        <button @click="addNotes('-D right leg check, ', 'plan')" class="flex-1 btn bg-violet-800 text-white">-D</button>
                                        <button @click="addNotes('+D right leg check, ', 'plan')" class="flex-1 btn bg-violet-800 text-white">+D</button>
                                    </div>
                                </div>
                                <!-- SOAP NOTES COLUMN-->
                                <div class="col-3">
                                    <div class="input-group">
                                        <span class="input-group-text">S <i @click="addSubjective()" class="bi bi-list-check"></i></span>
                                        <textarea name="subjective" id="subjective" cols="30" rows="4" class="form-control" v-model.lazy="appt.subjective"></textarea>
                                    </div>
                                    <!-- OBJECTIVE -->
                                    <div class="input-group">
                                        <span class="input-group-text">O </span>
                                        <textarea name="objective_text" id="objective_text" cols="30" rows="2" class="form-control" v-model.lazy="appt.objective_text"></textarea>
                                    </div>
                                    <!-- ASSESSMENT -->
                                    <div class="input-group">
                                        <span class="input-group-text">A</span>
                                        <textarea name="assessment" id="assessment" cols="30" rows="5" class="form-control" v-model.lazy="appt.assessment"></textarea>
                                    </div>
                                    <!-- PLAN -->
                                    <div class="row">
                                        <div class="flex flex-wrap">
                                            <button @click="addNotes('recheck next visit ', 'plan')" class="flex-1 btn btn-outline-primary">Next</button>
                                            <button @click="addNotes('the adjustment was well tolerated, ', 'plan')" class="flex-1 btn bg-emerald-500">&#128522;</button>
                                            <button @click="addNotes('an audible was heard ', 'plan')" class="flex-1 btn bg-orange-500">&#128266;</button>
                                            <button @click="addNotes('recheck in 1 week. ', 'plan')" class="flex-1 btn btn-outline-dark">1x</button>
                                            <button @click="addNotes('recheck in 2 weeks. ', 'plan')" class="flex-1 btn btn-outline-dark">2x</button>
                                            <button @click="addNotes('recheck in 3 weeks. ', 'plan')" class="flex-1 btn btn-outline-dark">3x</button>
                                            <button @click="addNotes('recheck in 4 weeks. ', 'plan')" class="flex-1 btn btn-outline-dark">4x</button>
                                            <button @click="addNotes('referred out. ', 'plan')" class="flex-1 btn btn-outline-danger">Refer</button>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <span class="input-group-text">P</span>
                                        <textarea name="plan" id="plan" cols="30" rows="4" class="form-control" v-model.lazy="appt.plan"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-slate-500" data-bs-dismiss="modal">Close</button>
                            <button @click="createAppt()" v-if="modal === 'create'" type="button" class="btn bg-blue-500">Create Appt</button>
                            <button @click="saveNotes(apptIndex, appt)" v-if="modal === 'update'" type="button" class="btn bg-blue-600">Save</button>
                            <button @click="saveNotes(apptIndex, appt, true)" v-if="modal === 'update'" type="button" class="btn bg-emerald-600">Save & Checkout</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!--    SIDE BAR (Payments)    -->
        <div class="col p-2">
            <payments-dash :payments="payments" :day="day" @paid-in-full="paidInFull"></payments-dash>
            <task-list></task-list>
        </div>
    </div>

</template>

<script>
import moment from "moment-timezone";
import PaymentsDash from "./PaymentsDash";
import TaskList from "./TaskList";
import {isString} from "lodash/lang";

export default {
    name: 'DocDash',
    components: {
        PaymentsDash,
        TaskList
    },
    props: [
        'message',
        'error',
        'errors',
        'appts'
    ],
    setup() { // using the composition API
        return {
            name
        }
    },
    mounted() {
        console.log('mounted');
        this.day = window.day;
        this.appts = window.appts;
        this.payments = window.payments;
        this.plans = window.plans;
        this.links = window.links;
        this.doctor = window.user;
        console.log(this.doctor);
        // console.log(this.appts);
        this.loading = false;
        // console.log(this.appts.map((appt) => appt.appt_time));
        console.log(this.appts.map((appt) => appt.payments));
        this.$toast.success("DocDash Loaded");
    },
    data() {
        return {
            appt: {},
            newAppt: {},
            selected: [],
            index: '',
            appts: [],
            links: [],
            patients: [],
            family: '',
            payments: [],
            plans: [],
            viewFinished: false,
            viewButtons: 'cervical',
            modal: '',
            loadingImage: '../images/IHC-loading.gif',
            loading: true,
            uri: '/api/',
        }
    },
    methods: {
        loadApptModal(index = null) {
            console.log('load appt modal');
            // set the modal type, assign appt, clear errors
            this.apptIndex = index;
            this.modal = 'update';

            // show modal
            $("#apptModal").modal("show");
        },
        select(index) {
            this.selected.indexOf(index) > -1
                ? this.selected.splice(this.selected.indexOf(index), 1)
                : this.selected.push(index);
            console.log('selected', this.selected, 'appt',this.getOnDeck[index].patient_id);
        },
        selectAll(){
            this.selected = this.getOnDeck.map(function(a,index){ return index });
            console.log('selected', this.selected);
        },
        createAppt(appt) {
            axios.post('/api/appt', appt)
                .then(response => {
                    // add task to array so we see it right away
                    this.appts.push(response.data.appt);
                    // display success message
                    this.$toast.info(response.data.message);
                    // close the modal
                    $("#apptModal").modal("hide");
                    // reset the task and errors
                    this.resetData();
                })
                .catch(error => {
                    this.handleError(error);
                })
        },
        isSelected() {
            if(this.selected.length < 1) {
                this.$toast.info("Select at least 1 appointment!")
                return false;
            } else {
                return true;
            }
        },
        bookNextAppt(date, hours) {

            // console.log('date',date, 'hours',hours);
            // check if selected
            let hasPatients = this.isSelected()
            // if patients selected, book the appts
            if(hasPatients) {
                this.selected.forEach((id) => {
                    let req = {
                        // patients are passed as an array on the admin side
                        patient_id: [this.getOnDeck[id].patient_id],
                        appt_time: this.getOnDeck[id].appt_time,
                        appt_date: new moment(date).format("YYYY-MM-DD"),
                        appt_type_id: this.getOnDeck[id].appt_type_id == 2 ? 2 :  4,
                        appt_reminder: 1,
                        appt_status_id: 2,
                        officehour_id: hours.id,
                        doctor_id: hours.doctor_id,
                        sendJson: true
                    };

                    if(!date) {
                        this.$toast.error('The office is CLOSED that day!');
                    }
                    // add the appointment to the db
                    axios.post('/api/appt', req).then(response => {
                        console.log(response.data);
                        // handle message responses
                        this.handleResponse(response)
                    }).catch(error => {
                        console.log(error);
                        this.handleError(error)
                    })
                });
            }
            this.resetData();
        },
        updateApptStatus(status) {
            // select appointment(s)
            let $selected = this.isSelected();
            // update each appt
            if($selected) {
                this.selected.forEach((id) => {
                    this.appt = this.getOnDeck[id];
                    let updated = {
                        appt_status_id: status,
                        appt_type_id: status === 2 ? 2 : this.appt.appt_type_id
                    };
                    // update the server
                    this.updateAppt(id, this.appt, updated);
                });
                this.resetData();
            }
        },
        updateApptType(type) {
            console.log('update appt type', type);
            let $selected = this.isSelected();
            if(type == 11) {
                console.log('type is 11, open modal ', type);
                console.log(this.selected);
                let index = this.selected[0];
                this.appt = this.getOnDeck[index];
                console.log(index, this.appt);
                this.loadApptModal(index);
            } else {
                console.log('type is not 11, mark clear', type);
                if($selected) {
                    this.selected.forEach((id) => {
                        this.appt = this.getOnDeck[id];
                        let newData = {
                            appt_status_id: 9,
                            appt_type_id: type,
                            subjective: this.appt.subjective ? this.appt.subjective : this.addSubjective(),
                            assessment: 'clear upper cervical check, no adjustment needed today',
                            plan: 'recheck next visit'
                        };
                        this.sendBoxMessage(this.appt)
                        this.updateAppt(id, this.appt, newData)
                    });
                    this.resetData();
                }
            }
            return;
        },
        saveNotes(index, appt, checkout = null){
            this.appt = this.getOnDeck[index];
            // saves the appointment and/or checks out
            let updated = {
                appt_type_id: checkout ? 11 : (appt.appt_type_id < 1 ? 11 : appt.appt_type_id),
                appt_status_id: checkout ? 9 : appt.appt_status_id,
                subjective: appt.subjective ? appt.subjective : this.addSubjective(),
                objective_text: appt.objective_text,
                plan: appt.plan ? appt.plan : 'Legs balanced post check.  Recheck next visit.',
                assessment: appt.assessment
            };
            this.updateAppt(index, this.appt, updated);
            this.resetData();
        },
        updateAppt(index, appt, updated) {
            axios.patch('/api/appts/' + appt.id, updated)
                .then(response => {
                    // find the index of the appointment
                    let pos = this.appts.findIndex(i => i.id === appt.id);
                    // remove the old one
                    this.appts.splice(pos, 1);
                    // push in the updated version
                    this.appts.push({...appt, ...updated});
                    // display success message
                    this.$toast.info(response.data.message ? response.data.message : response.data.error);
                    // close the modal
                    $("#apptModal").modal("hide");
                    this.resetData();
                })
                .catch(error => {
                    this.$toast.error(error);
                })
        },
        deleteAppt(index) {
            // Confirm deletion
            let confirmBox = confirm('Are you SURE you want to delete this?');
            if(confirmBox === true) {
                axios.delete(this.uri + this.getOnDeck[index].id)
                    .then(response => {
                        // remove task from the list of tasks
                        this.$delete(this.apptList, index);
                        // display success message
                        this.$toast.success(response.data.message);
                    }).catch(error => {
                    this.handleError(error);
                });
            }
        },
        addNotes(value, type) {
            this.appt[type] =  this.appt[type] ? this.appt[type] : '';
            this.appt[type] += value;
            console.log(this.appt);
            document.getElementById(type).value = this.appt[type];
        },
        addSubjective() {
            let subjective = ['the patient is here to be checked for chiropractic subluxation.','the patient is here for a chiropractic wellness checkup','the patient is here to be checked as scheduled'];
            let random = Math.floor(Math.random() * subjective.length);
            console.log(random);
            this.appt.subjective =  subjective[random];
            console.log(this.appt.subjective);
            document.getElementById("subjective").value = this.appt.subjective;
            return subjective[random];
        },
        sendMessage() {
            let message = prompt('What message would you like to send?')
            let hasPatients = this.isSelected()
            // if patients selected, book the appts
            if(hasPatients) {
                let config = {
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    }
                }
                this.selected.forEach((id) => {
                    let req = {
                        appt: this.getOnDeck[id].id,
                        message: message
                    }

                    axios.post('/api/appt/message', req, config)
                        .then(response => {
                            console.log(response.data);
                            this.handleResponse(response)
                        })
                        .catch(error => {
                            console.log(error);
                            this.handleError(error)
                        });
                })
            }
            this.resetData()
        },
        sendBoxMessage(appt) {
                let config = {
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    }
                }
                let req = {
                    appt: appt.id
                }
                    axios.post('/api/appt/boxMessage', req, config)
                        .then(response => {
                            console.log(response.data);
                            this.handleResponse(response)
                        })
                        .catch(error => {
                            console.log(error);
                            this.handleError(error)
                        });
        },
        resetData(index = null) {
            console.log('Reset Data Index: ' + index);
            // set the type of modal
            this.modal = index === null ? 'create' : 'update';
            // reset the appt to blank or assign selected
            this.appt = index === null
                ? {} : this.appt = this.appts[index];
            this.index = '';
            this.selected = [];
        },
        paidInFull(payment) {
            console.log('DocDash Paid In Full: payment', payment);
            let pos = this.appts.findIndex(i => i.patient_id === payment.patient_id);
            this.appt = this.appts[pos];
            console.log(this.appt);
            // update appt.due and mark_due fields
            if(Array.isArray(this.appt.due)) {
                // find the payment
                let pmtIndex = this.appt.due.findIndex(i => i.id === payment.id);
                // remove the payment from list of due
                this.appt.due.splice(pmtIndex, 1);
            } else {
                this.appt.due = null;
                this.appt.mark_due = false;
            }
            //update appts list
            this.appts.splice(pos, 1);
            this.appts.push(this.appt);
        },
        handleResponse(response) {
            if(response.data.message && !response.data.messages) {
                if(response.data.message.message) {
                    this.$toast.info(response.data.message.message);
                } else {
                    this.$toast.info(response.data.message);
                }
            }
            if(response.data.success) {
                this.$toast.success(response.data.success);
            }
            if(response.data.error && !response.data.errors) {
                this.$toast.error(response.data.error, { duration: false });
            }
            if(response.data.errors) {
                console.log(response.data.errors);
                response.data.errors.forEach((error) => {
                    this.$toast.error(error,{ duration: false });
                });
            }
            if(response.data.messages) {
                console.log(response.data.messages);
                response.data.messages.forEach((msg) => {
                    this.$toast.success(msg, {duration: false})
                })
            }
            if(isString(response.data)) {
                this.$toast.info(response.data)
            }
        },
        handleError(error) {
            this.$toast.error(error, {duration: false});
        },
        moment,
    },
    computed: {
        countOnDeck() {
            return this.viewFinished ? this.appts.filter((appt) => appt.appt_status_id !== 2).length : this.appts.filter((appt) => appt.appt_status_id == 2).length;
        },
        getOnDeck() {
            return this.viewFinished ?
                this.appts.filter((appt) => appt.appt_status_id !== 2) :
                this.appts.filter((appt) => appt.appt_status_id == 2);
        },
        getFinished() {
            return this.viewFinished && this.countOnDeck < 1 ? !this.viewFinished : this.viewFinished;
        }
    }
}
</script>
