<template>
    <!-- Your TasksComponent component's template -->
    <div class="p-0 m-0">
        <div v-if="loading">
            <img :src="loadingImage" alt="" width="100%" class="rounded mx-auto">
        </div>
        <div v-else>
            <button @click="loadTaskModal()" class="rounded w-full py-2 px-4 bg-teal-400 mb-4">Add New Task</button>
            <table class="table">
                <tr v-for="(task, index) in tasks">
                    <td>{{  index+1 }}</td>
                    <td>{{  task.name }}</td>
                    <td>{{  task.body }}</td>
                    <td><button @click="loadTaskModal(index)" class="btn bg-emerald-300">Edit</button></td>
                    <td><button @click="deleteTask(index)" class="btn bg-rose-600">Delete</button></td>
                </tr>
            </table>
        </div>

        <!-- Task Modal -->
        <div class="modal fade" id="taskModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title capitalize" id="taskModalLabel"> {{ modal }} Task</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" v-for="error in errors">{{ error }}</div>
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input v-model="task.name" type="text" id="name" class="form-control">
                        </div>
                        <div class="form-group">

                            <label for="body">Description</label>
                            <input v-model="task.body" type="text" id="body" class="form-control">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn bg-slate-500" data-bs-dismiss="modal">Close</button>
                        <button @click="createTask()" v-if="modal === 'create'" type="button" class="btn bg-blue-500">Save</button>
                        <button @click="updateTask()" v-if="modal === 'update'" type="button" class="btn bg-blue-500">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script nonce="vue-script">
import moment from "moment";
export default {
    name: 'TaskList',
    mounted() {
        console.log('Tasks Component mounting...')
        this.loadTasks();
    },
    data() {
        return {
            //
            task: {
                name: '',
                body: ''
            },
            tasks: [],
            modal: '',
            message: '',
            errors: [],
            loadingImage: '../images/IHC-loading.gif',
            loading: true,
            uri: '/api/tasks/'
        }
    },
    methods: {
        loadTasks() {
            axios.get(this.uri).then(response => {
                // save tasks from backend to the view
                this.tasks = response.data.tasks
                this.loading = false;
                console.log(this.tasks);
                this.$toast.success('Tasks Loaded.');
            }).catch(error => {
                // display errors getting list of tasks
                this.showErrors(error);
            })
        },
        loadTaskModal(index = null) {
            // set the modal type, assign task if selected, clear errors
            this.resetData(index);
            // show the modal
            $("#taskModal").modal("show");

        },
        createTask() {
            axios.post(this.uri, this.task)
                .then(response => {
                    // add task to array so we see it right away
                    this.tasks.push(response.data.task);
                    // display success message
                    toastr.success(response.data.message);
                    // close the modal
                    $("#taskModal").modal("hide");
                    // reset the task and errors
                    this.resetData();
                })
                .catch(error => {
                    this.showErrors(error);
                })
        },
        updateTask() {
            axios.patch(this.uri + this.task.id, { name: this.task.name, body: this.task.body}).then(response => {
                // hide the modal
                // add task to array so we see it right away
                this.tasks[this.task.id] = this.task;
                // display success message
                toastr.success(response.data.message);
                // close the modal
                $("#taskModal").modal("hide");
                // reset the task and errors
                this.resetData();
            })
                .catch(error => {
                    this.showErrors(error);
                })
        },
        deleteTask(index) {
            // Confirm deletion
            let confirmBox = confirm('Are you SURE you want to delete this?');
            if(confirmBox === true) {
                axios.delete(this.uri + this.tasks[index].id)
                    .then(response => {
                        // remove task from the list of tasks
                        this.$delete(this.tasks, index);
                        // display success message
                        toastr.success(response.data.message);
                    }).catch(error => {
                    this.showErrors(error);
                });
            }
        },
        showErrors(error) {
            console.log(error.response.data);
            if(error.response.data.errors.name) {
                this.errors.push(error.response.data.errors.name[0]);
            }

            if(error.response.data.errors.body) {
                this.errors.push(error.response.data.errors.body[0]);
            }
        },
        resetData(index = null) {
            console.log('Index: ' + index);
            // set the type of modal
            this.modal = index === null ? 'create' : 'update';
            // reset the task to blank or assign selected
            this.task = index === null
                ? {
                    name: '',
                    body: ''
                } : this.task = this.tasks[index];
            // clear any errors
            this.errors = []
        }
    }
}
</script>
