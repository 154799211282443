<template>
    <table>
        <thead>
            <tr class="flex mx-auto gap-4">
                <th class="flex gap-6 py-2" colspan="4">
                    <svg  @click="this.viewCollected = !this.viewCollected" :class="{'stroke-red-500': !this.viewCollected, 'stroke-emerald-500': this.viewCollected}" class="h-8 w-8 text-red-500 float-left"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />  <circle cx="16" cy="12" r="3" /></svg>
                    <div>{{ viewCollected ? 'Collected: ' : 'Due: '}}<span :class="{'text-emerald-500': viewCollected, 'text-rose-800': !viewCollected}" class="mr-4 py-4">${{ viewCollected ? totalCollected : totalDue }}</span></div>
                    <svg @click="this.viewCashBox = !this.viewCashBox" class="h-8 w-8 text-green-500 float-right"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"/>
                    </svg>
                </th>
            </tr>
            <tr v-if="!viewCollected" class="flex mx-auto gap-2">
                <th class="flex gap-4 mr-4 mx-auto" colspan="4">
                    <a :href="'/payments/'+ this.payment.id +'/edit'"><svg class="h-8 w-8 text-slate-500"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg></a>
                    <a :href="this.payment.checkoutUrl"><svg class="h-8 w-8 text-amber-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </svg></a>
                    <a @click="paidInFull(this.index, 'Cash')"><svg class="w-8 h-8 stroke-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path></svg></a>
                    <a @click="paidInFull(this.index, 'Check')"><svg class="w-8 h-8 stroke-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg></a>
                    <a @click="paidInFull(this.index, 'Square')"><svg class="w-8 h-8 stroke-violet-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path></svg></a>
                    <a @click="paidInFull(this.index, 'Barter')"><svg class="w-8 h-8 stroke-rose-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"></path></svg></a>
                </th>
        </tr>
            <tr v-if="viewCollected" class="flex mx-auto gap-2">
                <th class="flex mx-auto" colspan="4">
                    <a :href="'/payments/'+ this.payment.id +'/edit'"><svg class="h-8 w-8 text-green-500"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg></a>
                    <a :href="'/payment/'+ this.payment.id + '/receipt'" class="px-4 py-2 rounded m-1">Receipt</a>
                    <a :href="'/patient/'+ this.payment.patient_id + '/payments/year/' + moment().format('YYYY')" class="px-4 py-2 rounded m-1">{{ moment().format('YYYY')}}</a>
                    <a :href="'/patient/'+ this.payment.patient_id + '/payments/year/' + moment().subtract(1,'year').format('YYYY')" class="px-4 py-2 rounded m-1">{{  moment().subtract(1,'year').format('YYYY') }}</a>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pmt, index) in getPmts" class="odd:bg-gray-300 hover:bg-blue-300 flex">
            <td class="py-4 px-2">
                <input type="checkbox" @click="selectPmt(index, pmt)" class="h-6 w-6">
            </td>
            <td class="py-4 px-2">{{ pmt.patient.nickname }}</td>
            <td class="py-4 px-2">{{ !pmt.pmt_amt ? moment(new Date(pmt.pmt_duedate)).format('MMM Do') : pmt.pmt_type }}</td>
            <td class="py-4 px-2">${{ pmt.pmt_amt ? pmt.pmt_amt : pmt.due_amt }}</td>
        </tr>
        </tbody>
    </table>

<!--    <button href="/payments/create/" class="btn bg-blue-600 text-white hover:bg-blue-800 col-12">Add Payment</button>-->

    <!--            view cash box breakdown -->
    <table class="table table-striped" v-if="this.viewCashBox">
        <thead>
        <tr>
            <th colspan="2" class="uppercase font-semibold py-4 px-2">Payments By Type</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="type in totalByType">
            <td>{{ type.type }}</td>
            <td>${{ type.total }}</td>
        </tr>
        </tbody>
    </table>

</template>

<script nonce="vue-script">
import moment from "moment";
export default {
    name: 'PaymentsDash',
    props: ['payments', 'day'],
    emits: ['paid-in-full'],
    mounted() {
        console.log('payments: ', this.payments);
        console.log('day: ', this.day);
        // this.loadPaymentData();

        this.$toast.success('PaymentsDash Loaded.')
    },
    data() {
        return {
            payment: {},
            index: '',
            types: ['Cash', 'Check', 'Square', 'Box', 'Barter'],
            viewCollected: false,
            viewCashBox: false,
        }
    },
    methods: {
        selectPmt(index, pmt) {
            this.payment = pmt;
            this.index = index;
            console.log(this.getPmts[index]);
            console.log('pmt: ', this.payment, 'index: ', this.index);
        },
        paidInFull(index, type) {
            if (!index || type) {
                this.$toast.info("Select a payment first.");
            }
            console.log('paidInFull index: ' + index + ' type: ' + type);
            // update the local payment
            this.payment = this.getPmts[index];
            this.payment.pmt_amt = this.payment.due_amt;
            this.payment.pmt_type = type;
            this.payment.paid_date = this.day;
            this.payment.pmt_paid = 1;

            console.log('payment to be updated: ', this.payment);

            axios.patch('/api/payments/' + this.payment.id, this.payment)
                .then(response => {
                    // update the local list of payments
                    let pos = this.payments.findIndex(p => p.id === this.payment.id);
                    this.payments.splice(pos, 1);
                    this.payments.push(this.payment);
                    this.$emit('paid-in-full', this.payment);
                    // display success message
                    this.$toast.success(response.data.message);
                })
                .catch(error => {
                    this.$toast.error(error);
                })
        },
        showErrors(error) {
            if (!error.response) {
                this.$toast.error(error);
            } else {
                if (error.response.data.errors && error.response.data.errors.name) {
                    this.$toast.error(error.response.data.errors.name[0]);
                }
                if (error.response.data.errors && error.response.data.errors.body) {
                    this.$toast.error(error.response.data.errors.body[0]);
                }
            }
        },
        moment
    },
    computed: {
        totalDue() {
            return this.payments ? [...this.payments]
                .map(payment => payment.due_amt)
                .reduce((prev, curr) => prev + curr, 0) : 0;
        },
        totalCollected() {
            return this.payments ? [...this.payments]
                .map(pmt => pmt.pmt_amt)
                .reduce((prev, curr) => prev + curr, 0) : 0;
        },
        totalByType() {
            const breakdown = [];
            this.types.forEach((type) => {

                // filter payments by type and total it
                let total = [...this.payments]
                    .filter((payment) => payment.pmt_type == type)
                    .map(payment => payment.pmt_amt)
                    .reduce((prev, curr) => prev + curr, 0);
                // add to breakdown if a payment was made with that type
                breakdown.push({
                    type: type,
                    total: total ? total : 0
                });
            });
            return breakdown;
        },
        countPmts() {
            return this.viewCollected ? this.payments.filter((pmt) => pmt.due_amt - pmt.pmt_amt - pmt.writeoff == 0).length : this.payments.filter((pmt) => pmt.due_amt - pmt.pmt_amt - pmt.writeoff > 0).length;
        },
        getPmts() {
            return this.viewCollected ? this.payments.filter((pmt) => pmt.due_amt - pmt.pmt_amt - pmt.writeoff == 0) : this.payments.filter((pmt) => pmt.due_amt - pmt.pmt_amt - pmt.writeoff > 0);
        }
    }
}

</script>
